body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.search-result-name {
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 26px !important;
  letter-spacing: -0.254925px !important;
  color: #c50c1f !important;
}

.map-pin {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.patient-priority-background {
  background: #eaf2fb;
}
.patient-priority-background:nth-of-type(odd) {
  background: #eaf2fb80;
}

.formik-flex {
  display: flex;
  flex-direction: column;
}

.care-finder-results {
  width: 100%;
  overflow-y: scroll;
}

.search-result-rectangle {
  box-shadow: '0px 1px 3px rgba(0, 0, 0, 0.5)';
}

.survey-wrapper .sv_window {
  position: fixed;
  background-color: #d50132;
  right: -220px !important; /* override inline style */
  bottom: 50px !important; /* override inline style */
  transition: 0.6s ease;
  opacity: 1;
  z-index: 10000;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  width: unset !important; /* overwrite library inline style */
}

.survey-wrapper .sv_window:hover {
  right: 0 !important;
}

.survey-wrapper a {
  background-color: #d50132;
  right: -220px !important; /* override inline style */
  bottom: 40px !important; /* override inline style */
  transition: 0.6s ease;
  z-index: 10000;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
}

.survey-wrapper .sv_window:hover {
  right: 0 !important;
}

.survey-wrapper .sv_window_title {
  background-color: unset;
  color: white;
  border-bottom: none;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.sv_window_title:before {
  font-family: 'Material Icons';
  content: 'rate_review';
  display: block;
  width: 30px;
  height: 30px;
  font-size: x-large;
  float: left;
  margin: 0 5px;
  cursor: pointer;
}

.sv_window_content {
  background-color: white;
}

.survey-wrapper .sv_window_title {
  background-color: unset;
  color: white;
  border-bottom: none;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.sv_window_title:before {
  font-family: 'Material Icons';
  content: 'rate_review';
  display: block;
  width: 30px;
  height: 30px;
  font-size: x-large;
  float: left;
  margin: 0 5px;
  cursor: pointer;
}

.sv_window_content {
  background-color: white;
}

.gm-style-iw-t {
  display: none;
}

/* At the moment of writing, there was no way of theming MuiBox */
.survey-popover-box {
  position: fixed;
  display: inline-flex;
  bottom: 4rem;
  right: 0;
  z-index: 1050;
  transform-origin: right bottom;
  transform: rotate(-90deg) translate(100%, 0);
}
